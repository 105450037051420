import styled from "@emotion/styled";

const ButtonUnstyled = styled.button`
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export default ButtonUnstyled;
