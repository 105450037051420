import React from "react"

import { Link } from "gatsby"

import Logo from "./logo"
import styled from "@emotion/styled"

import ButtonUnstyled from "./ButtonUnstyled"

import usePopover from "../hooks/usePopover"

const MenuItem = styled(Link)({
  color: "rgb(13, 33, 70)",
  textAlign: "center",
  "&:visited": {
    color: "inherit",
  },
  "&:hover": {
    color: "rgb(239, 141, 144)",
  },
  "&:active": {
    textDecoration: "underline",
  },
})

const MenuDropdownItem = ({ href, label, icon }) => {
  return (
    <div>
      <a
        css={{
          paddingTop: 5,
          paddingBottom: 5,
          textAlign: "left",
          color: "rgb(13, 33, 70)",
          alignItems: "center",
          display: "flex",
          "&:visited": {
            color: "inherit",
          },
          "&:hover": {
            color: "rgb(239, 141, 144)",
          },
        }}
        href={href}
      >
        {icon}
        <span css={{ marginLeft: 10, fontSize: 16 }}>{label}</span>
      </a>
    </div>
  )
}

const Header = () => {
  const {
    ref,
    isVisible: showDropdown,
    setIsVisible: setShowDropdown,
  } = usePopover(false)
  return (
    <header>
      <section className="container">
        <div className="row">
          <Link to="/">
            <div className="logo">
              <Logo />
            </div>
          </Link>
          <div className="row">
            <div
              css={{
                marginRight: 20,
                color: "rgb(13, 33, 70)",
                textAlign: "center",
                position: "relative",
              }}
            >
              <ButtonUnstyled
                css={{
                  "&:hover": {
                    color: "rgb(239, 141, 144)",
                  },
                }}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                Reserve
              </ButtonUnstyled>
              <div
                ref={ref}
                css={{
                  display: showDropdown ? "block" : "none",
                  position: "absolute",
                  padding: 10,
                  backgroundColor: "#F8F8F8",
                  width: 200,
                  marginTop: 10,
                  boxShadow: "0 2px 4px 0px rgba(0, 0, 0, .3)",
                  borderRadius: 8,
                  right: 0,
                }}
              >
                <MenuDropdownItem
                  href="tel:+34971945363"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      fill="currentColor"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                    </svg>
                  }
                  label="Call us"
                />
                <div css={{ height: 10 }} />
                <MenuDropdownItem
                  label="Email us"
                  href="mailto:perlakitchenbar@gmail.com"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      fill="currentColor"
                    >
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  }
                />
              </div>
            </div>
            <MenuItem
              activeStyle={{ textDecoration: "underline" }}
              to="/food-and-drink"
            >
              Menu
            </MenuItem>
          </div>
        </div>
      </section>
    </header>
  )
}

export default Header
